// import img01 from "../assets/images/olympo36/img01.jpg"
import img01 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---acabado-techo.jpg"
// import img02 from "../assets/images/olympo36/img02.jpg"
import img02 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---acabado-lateral.jpg"
// import img03 from "../assets/images/olympo36/img03.jpg"
import img03 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---acabado-piso.jpg"
// import img04 from "../assets/images/olympo36/img04.jpg"
import img04 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---iluminacion.jpg"
// import img05 from "../assets/images/olympo36/img05.jpg"
import img05 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---paquetera.jpg"
import img06 from "../assets/images/OLYMPO35/ESTANDAR/cabina-conductor---cupula.jpg"
// import img07 from "../assets/images/olympo36/img07.jpg"
import img07 from "../assets/images/OLYMPO35/ESTANDAR/cabina-conductor---mampara-divisoria.jpg"
// import img08 from "../assets/images/olympo36/img08.jpg"
import img08 from "../assets/images/OLYMPO35/ESTANDAR/cabina-conductor---tablero-de-control.jpg"
// import img09 from "../assets/images/olympo36/img09.jpg"
import img09 from "../assets/images/OLYMPO35/ESTANDAR/cabina-conductor---grada-de-ingreso.jpg"
import img10 from "../assets/images/OLYMPO35/ESTANDAR/exterior-1.jpg"
// import img11 from "../assets/images/OLYMPO35/ESTANDAR/exterior-1.jpg"
import img11 from "../assets/images/OLYMPO35/ESTANDAR/exterior-2.jpg"
// import img12 from "../assets/images/olympo36/img12.jpg"
// import img13 from "../assets/images/olympo36/img13.jpg"
import img13 from "../assets/images/OLYMPO35/ESTANDAR/calefaccion.jpg"
// import img14 from "../assets/images/olympo36/img14.jpg"
import img14 from "../assets/images/OLYMPO35/ESTANDAR/salon-pasajeros---asientos.jpg"
// import img15 from "../assets/images/olympo36/img15.jpg"
import img15 from "../assets/images/OLYMPO35/OPCIONALES/aire-acondicionado.jpg"
// import img16 from "../assets/images/olympo36/img16.jpg"
import img16 from "../assets/images/OLYMPO35/OPCIONALES/bano.jpg"
// import img17 from "../assets/images/olympo36/img17.jpg"
import img17 from "../assets/images/OLYMPO35/ESTANDAR/conduccion---espejos-retrovisores.jpg"
// import img18 from "../assets/images/olympo36/img18.jpg"
import img18 from "../assets/images/OLYMPO35/ESTANDAR/conduccion---panel-de-control.jpg"
import img19 from "../assets/images/OLYMPO35/ESTANDAR/exterior-3.jpg"
import img22 from "../assets/images/olympo29/ESTANDAR/conduccion---accionamiento-de-puerta-de-servicio.jpg"
export const OlympoBlock1 = [
  
  {
    title: `ACABADO TECHO`,
    text: `Decorado con material PVC DECORATIVO que es impermeable, de facil limpieza, antinflamable y aislante térmico.`,
    img: img01,
  },
  {
    title: `ACABADO LATERALES`,
    text: ` Decorado con material PVC DECORATIVO.<br /> Perfilado de marcos de ventanas con PVC DECORATIVO de brillo natural.`,
    img: img02,
  },
  {
    title: `ACABADO DE PISO`,
    text: `Cubierto en PVC FLEXIBLE DE ALTO TRÁNSITO de alta resistencia al desgaste.<br /> Opciones de colores para el decorado del piso. Es antinflamable y retardante.`,
    img: img03,
  },
  {
    title: `ILUMINACIÓN`,
    text: ` Iluminación con tecnología LED a ambos lados del salón en la parte superior (techo).`,
    img: img04,
  },
  {
    title: `PAQUETERAS`,
    text: `LIGERAS y elaboradas en poliuretano rigido de alta densidad.<br /> Fijadas en el techo estratégicamente para EVITAR VIBRACIONES. Luces de lectura individuales.`,
    img: img05,
  },
  {
    title: `ASIENTOS`,
    text: `Inyectados en espuma de poliuretano flexible, opciones de tapizado en tela navallada o cuero sintético importado. <br />Personalización de asientos de acuerdo a necesidades del cliente.`,
    img: img14,
  },
  {
    title: `CALEFACCIÓN`,
    text: `Concentradores de calor y convección forzada. <br />Consta de aletas de aluminio (serpentín) distribuidos en todo el salón de pasajeros; sopladores eléctricos silenciosos para un flujo de calor homogeneo.`,
    img: img13,
  },
  
]
export const OlympoBlock2 = [
  {
    title: `TABLERO DE CONTROL`,
    text: `Diseñado para FACILITAR LA OPERACIÓN de los diferentes mandos de control del ómnibus; diseño AERODINÁMICO y protección externa con poliuretano rígido.`,
    img: img08,
  },
  {
    title: `GRADA DE INGRESO`,
    text: `Con ILUMINACIÓN PROPIA y fabricado en fibra de vidrio.<br /> Base estructural reforzado. `,
    img: img09,
  },
  {
    title: `CÚPULA`,
    text: `Gravado natural con lineas estelizadas decorativas y definición de contornos para la colocación de luces de cabina, parlantes, entre otros. <br /> Iluminación con sistema LED.`,
    img: img06,
  },
  {
    title: `MAMPARA DIVISORIA`,
    text: `Diseño decorativo y en armonia con los espacios que divide .<br /> Su ubicación obedece a la optimización entre el espacio del salón de pasajeros y la cabina de conducción.`,
    img: img07,
  },
  
  
]
export const OlympoBlock3 = [
  {
    title: `ISO FRONTAL`,
    text: `  AERODINÁMICO Y ROBUSTO`,
    img: img10,
  },
  {
    title: `ISO POSTERIOR`,
    text: ` ROBUSTO`,
    img: img11,
  },
  {
    
    title:"LATERAL",
    text:'AERODINÁMICO',
    img:img19
  },
  // {
  //   title: `BODEGAS`,
  //   text: ` Livianas, forradas en aluminio. Puertas de bodegas cuentan con mecanismo de seguridad de doble traba que garantizan un sello hermético.  `,
  //   img: img12,
  // },
]
export const OlympoBlock4 = [
  
  {
    title: `AIRE ACONDICIONADO`,
    text: `Sistema que intercambia aire fresco del medio ambiente, purificado y climatizado a una determinada temperatura. <br />Garantiza comodidad y bienestar a los pasajeros.`,
    img: img15,
  },
  {
    title: `BAÑO`,
    text: `Mixto, urinario con taza. Cuenta con sistema automático de extracción de olores.<br/> SANITIZACIÓN en base a la utilización de Ozono para la eliminación de agentes infectantes como virus y bacterias.`,
    img: img16,
  },
]
export const OlympoBlock5=[
  {
    title:"ESPEJOS RETROVISORES",
    text:'Eléctricos con mando selector que regulan el angulo de visión del conductor.<br /> Espejos calefactables para mejorar la visibilidad de conducción en zonas humedas.',
    img:img17
  },
  {
    title:'ACCIONAMIENTO PUERTA SERVICIO',
    text:'Control inalambrico para mayor seguridad de bloqueo/desbloqueo desde el exterior del bus.',
    img:img22
  },
  {
    title:'PANEL DE CONTROL',
    text:`Panel digital con tecnología de circuitos impresos con protección “Anti – solder” que garantizan la funcionalidad correcta del bus en los diferentes climas del Perú.`,
    img:img18
  }
]
export const OlympoBlock6=[
  
 
]